import { Button } from '@shared/components/button';
import { ExpandablePanel } from '@shared/components/expandable-panel';
import { Text } from '@shared/components/text';
import { Tooltip } from '@shared/components/tooltip';
import { RatingMethod } from '@shared/generated/graphql';
import { RateMetadata } from '@shared/types/quote';
import { usdFormatter } from 'clerk_common/stringification/numbers';
import { motion } from 'framer-motion';
import { isNil } from 'lodash';
import { useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { extractTransfixPercentiles } from '../utils/extractTransfixPercentiles';
import { DATRateWidget } from './DATRateWidget';

export const TabiRateWidget = ({ metadata }: { metadata: RateMetadata }) => {
  const identifiers = metadata?.response?.identifiers as string[];
  if (!identifiers?.length) return null;

  return (
    <Text type="custom" className="mt-2 text-[10px] text-gray-500">
      Rate requested with customer identifier{identifiers.length > 1 ? 's' : ''}
      : <strong>{identifiers.join(', ')}</strong>
    </Text>
  );
};

export const GreenscreensConfidenceLevel = ({
  metadata,
}: {
  metadata: RateMetadata;
}) => {
  const confidenceLevel = metadata?.response?.confidenceLevel;

  if (!confidenceLevel) return null;

  return (
    <div className="flex w-full flex-col gap-1">
      <div className="flex justify-between">
        <Text type="custom" className="text-[10px] text-gray-500">
          Confidence level:
        </Text>
        <Text type="custom" className="text-[10px] text-gray-500">
          {confidenceLevel}
        </Text>
      </div>
      <div className="h-1 w-full bg-gray-200">
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: `${confidenceLevel}%` }}
          transition={{ duration: 0.7 }}
          className={'bg-success-500 h-1'}
        />
      </div>
    </div>
  );
};

export const TransfixConfidenceLevel = ({
  metadata,
}: {
  metadata: RateMetadata;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const data = extractTransfixPercentiles(metadata);
  return (
    <ExpandablePanel
      summary={
        <div>
          <Button
            size="xs"
            variant="tertiary"
            onPress={() => setIsExpanded(!isExpanded)}
            icon={isExpanded ? <MdExpandLess /> : <MdExpandMore />}
          >
            {isExpanded ? 'Confidence' : 'Confidence'}
          </Button>
        </div>
      }
      expanded={isExpanded}
      className="w-full"
      setExpanded={setIsExpanded}
    >
      <div className="no-scrollbar flex w-full flex-col">
        {data.map((element) => {
          const opacity = (parseInt(element.opacity) + 50) / 150;
          const value = usdFormatter.format(element.value);
          return (
            <Tooltip
              content={`${element.label} - ${value}`}
              key={element.key}
              side="left"
            >
              <div
                className="text- flex w-full cursor-pointer flex-row justify-start px-1 py-[2px]"
                style={{
                  backgroundColor: `rgba(22,163,74, ${opacity})`,
                }}
              >
                <Text type="custom" className="text-[10px] text-white">
                  {element.label} - {value}
                </Text>
              </div>
            </Tooltip>
          );
        })}
      </div>
    </ExpandablePanel>
  );
};

export const EchoConfidenceLevel = ({
  metadata,
}: {
  metadata: RateMetadata;
}) => {
  const confidenceLevel = metadata?.response?.rate?.buyRateConfidence;

  if (isNil(confidenceLevel)) return null;

  const confidenceLevelDisplay = (
    <Text type="custom" className="text-[11px] text-gray-500">
      Confidence: {confidenceLevel.confidenceScore}
    </Text>
  );

  const rangeDisplay = areRangesMeaningful(confidenceLevel) ? (
    <Text type="custom" className="text-[11px] text-gray-500">
      ${confidenceLevel.rangeLower} - ${confidenceLevel.rangeUpper}
    </Text>
  ) : null;

  return (
    <div className="flex w-full flex-col gap-1">
      {confidenceLevelDisplay}
      {rangeDisplay}
    </div>
  );
};

type EchoConfidence = {
  confidenceScore: number;
  rangeLower: number;
  rangeUpper: number;
};

const areRangesMeaningful = (confidence: EchoConfidence) => {
  // NOTE(jacob): Echo (at least on their dev server) sometimes returns
  // -9999, which I'm assuming is a placeholder.
  return confidence.rangeLower > 0 && confidence.rangeUpper > 0;
};

export const getRateConfidence = ({
  ratingMethod,
  metadata,
}: {
  ratingMethod?: RatingMethod;
  metadata?: RateMetadata;
}) => {
  switch (ratingMethod) {
    case RatingMethod.GREENSCREENS:
      return metadata ? (
        <GreenscreensConfidenceLevel metadata={metadata} />
      ) : null;
    case RatingMethod.ECHO:
      return metadata ? <EchoConfidenceLevel metadata={metadata} /> : null;
    case RatingMethod.TRANSFIX:
      return metadata ? <TransfixConfidenceLevel metadata={metadata} /> : null;
    default:
      return null;
  }
};

export const RateWidget = ({
  ratingMethod,
  metadata,
}: {
  ratingMethod?: RatingMethod;
  metadata?: RateMetadata;
}) => {
  switch (ratingMethod) {
    case RatingMethod.GREENSCREENS:
      return null;
    case RatingMethod.DAT:
      return metadata ? <DATRateWidget metadata={metadata} /> : null;
    case RatingMethod.TABI:
      return metadata ? <TabiRateWidget metadata={metadata} /> : null;
    default:
      return null;
  }
};
