import { RatingMethod } from '@shared/generated/graphql';
import { DATRatingMethod, RateMetadata } from '@shared/types/quote';
import {
  formatPercentage,
  usdFormatter,
} from 'clerk_common/stringification/numbers';

export enum RateDetailSubtitleType {
  RATE = 'RATE',
  MILEAGE = 'MILEAGE',
}

export const shouldShowRateValue = ({
  metadata,
}: {
  metadata?: RateMetadata;
}): boolean => {
  return Boolean(
    metadata && metadata?.ratingMethod !== DATRatingMethod.HISTORY
  );
};

const lowAndHighToMinMaxDisplay = ({
  low,
  high,
  suffix,
  showPercentage,
}: {
  low: number;
  high: number;
  showPercentage: boolean;
  suffix?: string;
}) => {
  const averageRate = (low + high) / 2;
  return [low, high].map((r, i) => {
    const deltaNumber = (r - averageRate) / averageRate;
    const delta = formatPercentage(deltaNumber);
    const percentageChange = showPercentage
      ? ` (${deltaNumber < 0 ? '' : '+'}${delta})`
      : '';
    return `${i === 0 ? 'Min' : 'Max'}: ${usdFormatter.format(r)}${
      suffix ?? ''
    }${percentageChange}`;
  });
};

export const makeRateDetailSubtitleComponents = ({
  ratingMethod,
  metadata,
  type,
}: {
  ratingMethod?: RatingMethod;
  metadata?: RateMetadata;
  type: RateDetailSubtitleType;
}) => {
  switch (type) {
    case RateDetailSubtitleType.RATE:
      return makeRateDetailRateSubtitleComponents({ ratingMethod, metadata });
    case RateDetailSubtitleType.MILEAGE:
      return makeRateDetailMileageSubtitleComponents({
        ratingMethod,
        metadata,
      });
    default:
      return [];
  }
};

export const makeRateDetailMileageSubtitleComponents = ({
  ratingMethod,
  metadata,
}: {
  ratingMethod?: RatingMethod;
  metadata?: RateMetadata;
}) => {
  try {
    switch (ratingMethod) {
      case RatingMethod.DAT: {
        if (metadata?.ratingMethod === DATRatingMethod.RATE) {
          const { averageFuelSurchargePerTripUsd, mileage, perTrip } =
            metadata?.response?.rate || {};
          const { lowUsd, highUsd } = perTrip;
          const totalLowRate =
            (lowUsd + averageFuelSurchargePerTripUsd) / mileage;
          const totalHighRate =
            (highUsd + averageFuelSurchargePerTripUsd) / mileage;
          return lowAndHighToMinMaxDisplay({
            low: totalLowRate,
            high: totalHighRate,
            suffix: '/mi',
            showPercentage: false,
          });
        }
        return [];
      }

      case RatingMethod.GREENSCREENS: {
        const { lowBuyRate, highBuyRate } = metadata?.response || {};

        return lowAndHighToMinMaxDisplay({
          low: lowBuyRate,
          high: highBuyRate,
          suffix: '/mi',
          showPercentage: false,
        });
      }
      default:
        return [];
    }
  } catch (err) {
    console.error('Error making rate detail subtitle components', err);
    return [];
  }
};

export const makeRateDetailRateSubtitleComponents = ({
  ratingMethod,
  metadata,
}: {
  ratingMethod?: RatingMethod;
  metadata?: RateMetadata;
}) => {
  try {
    switch (ratingMethod) {
      case RatingMethod.DAT: {
        if (metadata?.ratingMethod === DATRatingMethod.RATE) {
          const { perTrip } = metadata?.response?.rate || {};
          const { lowUsd, highUsd } = perTrip;
          return lowAndHighToMinMaxDisplay({
            low: lowUsd,
            high: highUsd,
            showPercentage: true,
          });
        }
        return [];
      }

      case RatingMethod.GREENSCREENS: {
        const { lowBuyRate, highBuyRate, distance } = metadata?.response || {};

        return lowAndHighToMinMaxDisplay({
          low: lowBuyRate * distance,
          high: highBuyRate * distance,
          showPercentage: true,
        });
      }
      default:
        return [];
    }
  } catch (err) {
    console.error('Error making rate detail subtitle components', err);
    return [];
  }
};

export const getMileageRate = ({ metadata }: { metadata?: RateMetadata }) => {
  const mileageRate = metadata?.mileageRate;

  if (!mileageRate) return null;

  return `${usdFormatter.format(
    mileageRate.value
  )}/${mileageRate.units.toLowerCase()}`;
};
